<script lang="ts" setup>
import getYouTubeID from "get-youtube-id";

const props = defineProps({
  youtubeHref: {
    type: String,
    required: true,
  },
});

const generateYouTubeEmbedURl = () => {
  const id = getYouTubeID(props.youtubeHref);
  let url = `https://www.youtube.com/embed/${id}`;
  const params = new URLSearchParams();

  return url;
};
</script>

<template>
  <iframe
    class="aspect-[560/315] w-full my-2 rounded shadow-md"
    :src="generateYouTubeEmbedURl()"
    title="YouTube video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen
  />
</template>

<style scoped></style>
